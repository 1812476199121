<template>
    <div id="login-container" class="container" :class="$mq">
        <div class="logo"></div>

        <div class="site-connect">
            <div class="site-connect-title">{{ $t('access.select_site') }}</div>
            <div class="sites-container hide-scrollbar">
                <div class="row-site" v-for="(site, index) in sites" :key="index">
                    <div class="remove" @click="remove(site)"></div>
                    <div class="card-site" @click="loginSite(site)">
                        <div class="logo-site">
                            <img :src="site.account_logo" class="account-logo" />
                        </div>
                        <div class="text-content">
                            <div class="site-card-title">{{ site.account_name }}</div>
                            <div class="site-card-description">{{ site.location_name }}</div>
                        </div>
                    </div>
                </div>
                <div class="row-site" :class="'add-site'">
                    <div class="remove"></div>
                    <div class="card-site" @click="goToNormalLogin()">
                        <div class="logo-site">
                            <img :src="'~@/../img/add_site.svg'" class="account-logo" />
                        </div>
                        <div class="text-content">
                            <div class="site-card-title">{{ $t('access.new_site') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="login-bottom">
            <Copyright customClass="login-copyright"></Copyright>
            <div class="version">{{ $t('tooltip.version') }}: {{ appVersion }}</div>
        </div>
    </div>
</template>

<script>
import Copyright from '@/components/layout/Copyright'
export default {
    components: {
        Copyright
    },
    name: 'MultiAccountLogin',
    props: {
        sites: {
            type: Array,
            default: false
        }
    },
    computed: {
        appVersion() {
            return process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : ''
        }
    },
    methods: {
        goToNormalLogin() {
            this.$emit('goToNormalLogin')
        },
        loginSite(site) {
            if (fnCheckConnection()) {
                this.$emit('loginSite', site)
            } else {
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('login.no_internet') })
            }
        },
        remove(site) {
            var self = this
            this.$popup.delete({
                message: self.$t('login.popup_delete', { s: site.account_name + ' - ' + site.location_name }),
                title: self.$t('popup.confirm.title'),
                textSave: self.$t('menu_aux.action.delete'),
                textCancel: self.$t('menu_aux.action.cancel'),
                callSave: function() {
                    self.$emit('removeSite', site)
                }
            })
        }
    }
}
</script>

<style lang="scss">
#login-container.container {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(space-between);
    width: 100%;
    height: 100%;
    padding: 0px;

    .logo {
        @include background($image: img('logo_andy_small.png'), $position: center center);
        width: 100%;
        height: 30px;
    }
    .site-connect {
        height: calc(100% - 70px);
        width: 100%;
        overflow: hidden;

        .site-connect-title {
            @include font-size(sm);
            font-family: $text-bold;
            color: $info-s80;
            text-align: center;
            padding: 18px 0px;
        }
        .sites-container {
            @include display-flex();
            @include flex-direction(column);
            gap: 9px;
            overflow: auto;
            width: 100%;
            height: calc(100% - 60px);

            .row-site {
                width: 100%;
                height: 75px;
                min-height: 75px;
                @include display-flex();
                @include flex-direction(row);
                @include justify-content(space-between);
                gap: 12px;

                .remove {
                    @include background($image: img('remove.svg'), $position: center center);
                    @include display-flex();
                    @include align-self(center);
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                }

                .card-site {
                    @include display-flex();
                    @include flex-direction(row);
                    @include align-items(center);
                    @include justify-content(flex-start);
                    @include border-radius(4px);
                    @include background($image: img('right_neutro.svg'), $position: right 10px center, $size: 15px);
                    width: calc(100% - 45px);
                    height: 100%;
                    padding: 10px;

                    background-color: #fff;
                    cursor: pointer;

                    &:hover {
                        background-color: hover(#fff);
                    }

                    .logo-site {
                        @include display-flex();
                        @include justify-content();
                        width: 70px;
                        height: auto;
                    }

                    .text-content {
                        @include display-flex();
                        @include flex-direction(column);
                        padding-left: 6px;
                        width: calc(100% - 95px);
                        .site-card-title {
                            @include text-ellipsis();
                            @include font-size(xs);
                            font-family: $text-bold;
                            color: $inactive-s90;
                        }
                        .site-card-description {
                            @include text-ellipsis();
                            @include font-size(xs);
                            font-family: $text;
                            color: $inactive-s90;
                        }
                    }
                }
                &.add-site {
                    min-height: 65px;
                    height: 65px;

                    .remove {
                        background: border-box;
                        pointer-events: none;
                    }
                    .site-card-title {
                        text-transform: uppercase !important;
                        font-family: $text !important;
                    }
                }
            }
        }
    }
    .login-bottom {
        margin-top: 0px !important;
    }

    &.landscape {
        max-width: 700px;

        .site-connect-title {
            font-size: 22px !important;
        }
        .logo-site {
            width: 100px !important;
        }
        .text-content {
            width: calc(100% - 130px) !important;

            .site-card-title {
                font-size: 18px !important;
            }
            .site-card-description {
                font-size: 16px !important;
            }
        }
    }

    &.desktop {
        max-width: 700px;

        .site-connect-title {
            @include font-size(l);
        }
        .logo-site {
            width: 100px !important;
        }
        .text-content {
            width: calc(100% - 130px) !important;

            .site-card-title {
                font-size: 18px !important;
            }
            .site-card-description {
                font-size: 16px !important;
            }
        }
    }
}
</style>
